import React from 'react'
import Layout from '../components/Layout'
import CallBackBanner from '../components/CallBackBanner'
import GeneralHeader from '../components/Header/GeneralHeader'
import PromotionBanner from '../components/PromotionBanner/PromotionBanner'
import Faq from '../components/shared/FAQ/Faq'
import GeneralEnquiryForm from '../components/shared/Form/GeneralEnquiryForm'
import ImageAndTextColumns from '../components/ImageAndTextColumns/ImageAndTextColumns'
import img from '../images/industry-sector/team_industry.svg'
import IndustryGrid from '../components/IndustrySector/IndustryGrid'
import icon1 from '../images/tax-credit/aerospace.svg'
import icon2 from '../images/tax-credit/cctv.svg'
import icon3 from '../images/tax-credit/paint.svg'
import icon4 from '../images/tax-credit/construction.svg'
import icon5 from '../images/tax-credit/electronics.svg'
import icon6 from '../images/tax-credit/engineering.svg'
import icon7 from '../images/tax-credit/materials.svg'
import icon8 from '../images/tax-credit/projects.svg'
import icon9 from '../images/tax-credit/sustainablity.svg'
import icon10 from '../images/tax-credit/pharmaceutical.svg'
import icon11 from '../images/tax-credit/testing.svg'
import { qs } from './claims-process'

const breadcrumbData = [
  {
    title: 'Home',
    url: '/',
  },
  {
    title: 'Industry Sector',
    url: '/industry-sector',
  },
]

const cards = [
  {
    icon: icon1,
    alt: 'Airplane',
    title: 'Aerospace and Defence',
    body:
      'Do your projects involve high quality type material finishes? Do you design bespoke products for the aerospace industry using different types of materials?',
    linkUrl: '/aerospace-and-defence',
  },
  {
    icon: icon2,
    alt: 'Alarms/CCTV',
    title: 'Alarms/CCTV',
    body: 'Are you breaking new ground in optical surveillance?...',
    linkUrl: '/alarms',
  },
  {
    icon: icon3,
    alt: 'Chemicals, Paints and Adhesive',
    title: 'Chemicals, Paints and Adhesive',
    body:
      'Are you using new or unusual materials, design processes or manufacturing techniques on your products?',
    linkUrl: '/chemicals-paints-adhesive',
  },
  {
    icon: icon4,
    alt: 'Construction and Building Material',
    title: 'Construction and Building Material',
    body:
      'Do you design bespoke and iconic features e.g. façades, staircases, furniture or roofing systems? Are you using existing materials, design processes…',
    linkUrl: '/construction-and-building-material',
  },
  {
    icon: icon5,
    alt: 'Electronics and Electrical ',
    title: 'Electronics and Electrical ',
    body:
      'Have you developed processes or manufacturing techniques that are out of the ordinary? Do you use new or unusual materials in your work?',
    linkUrl: '/electronics-and-electrical',
  },
  {
    icon: icon6,
    alt: 'Engineering and Machinery Design',
    title: 'Engineering and Machinery Design',
    body:
      'Do your designs and products involve new or unusual processes, materials or systems? Are you manufacturing products to address new markets?',
    linkUrl: '/engineering-and-machinery-design',
  },
  {
    icon: icon7,
    alt: 'Materials, Finishes and Construction…',
    title: 'Materials, Finishes and Construction…',
    body:
      'Are you using existing materials, design processes or manufacturing techniques on your projects, but in a new or novel way?',
    linkUrl: '/materials-finishes-and-construction',
  },
  {
    icon: icon8,
    alt: 'Projects',
    title: 'Projects',
    body:
      'Are you working to develop new processes or methods? Are you making adaptations to existing software or designs?',
    linkUrl: '/projects',
  },
  {
    icon: icon9,
    alt: 'Sustainability',
    title: 'Sustainability',
    body:
      'Are you seeking sustainable certification on your projects, such as BREEAM, Passivhaus or LEED? Do you design or integrate sustainable systems into your projects?',
    linkUrl: '/sustainability',
  },
  {
    icon: icon10,
    alt: 'Pharmaceuticals, Biotechnology and Medical Sciences',
    title: 'Pharmaceuticals, Biotechnology...',
    body:
      'Are you developing new drugs or administration methods? Are you investigating the structures and effects of viruses or bacteria? Are you working to explore how the body or mind responds to pathogens or other external stimuli?',
    linkUrl: '/pharmaceuticals-biotechnology-and-medical-science',
  },
  {
    icon: icon11,
    alt: 'Testing',
    title: 'Testing',
    body:
      'Do your projects involve Business Information Modelling (BIM) or software testing, e.g. structural, thermal or wind software modelling?',
    linkUrl: '/testing',
  },
]
const IndustrySector = () => {
  return (
    <Layout
      activeLink="/industry-sector"
      title="R&D Industry Sector"
      description="R&D Industry Sector"
    >
      <GeneralHeader
        breadcrumbData={breadcrumbData}
        heading="Sectors we serve"
      />

      <ImageAndTextColumns
        tag="QUALIFYING INDUSTRY SECTORS"
        heading="R&D tax credits are waiting to be claimed"
        body={[
          'There is no restriction to the industry sector in which a company operates – all R&D projects are considered on their individual merits. We work across all sectors and have consistently delivered successful claims for firms working in digital technology, manufacturing, architecture, engineering, pharmaceuticals, food, ICT, construction and renewable energies, to name just a few.',
          'HMRC guidelines suggest that when making the claim for tax relief a company should demonstrate there has been a scientific or technological advance as a result of the R&D activity and also whether any uncertainties have been resolved which were not readily deducible by a competent professional in the field.',
        ]}
        img={img}
        imgAlt="People in a meeting"
      />
      <IndustryGrid
        heading="Where R&D might apply in your sector:"
        cards={cards}
      />
      <PromotionBanner />
      <Faq questions={qs} />
      <GeneralEnquiryForm />
      <CallBackBanner />
    </Layout>
  )
}

export default IndustrySector
