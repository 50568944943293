import React, { useState } from 'react'
import styled from 'styled-components'
import InlineLink from '../shared/InlineLink'
import MainGrid from '../styling/MainGrid'
import Button from '../shared/Button/Button'
import { scrollTo } from '../../util/functions'

interface ICard {
  icon?: string
  alt?: string
  title?: string
  body?: string
  linkUrl?: string
}

interface IIndustryGrid {
  heading?: string
  cards: Array<ICard>
}
const IndustryGrid = ({ heading, cards }: IIndustryGrid) => {
  const [showAllCards, toggleCards] = useState(false)
  const loadMore = () => {
    if (showAllCards) {
      setTimeout(() => {
        scrollTo('industry-sector')
      }, 300)
    }
    toggleCards(!showAllCards)
  }
  return (
    <Container>
      <MainGrid>
        {heading && <h4>{heading}</h4>}

        <Grid>
          {cards &&
            cards.map((item, key) => {
              return (
                <Card key={key}>
                  {item.icon && <Icon src={item.icon} alt={item.alt} />}
                  {item.title && <h6>{item.title}</h6>}
                  {item.body && <p>{item.body}</p>}
                  {item.linkUrl && (
                    <InlineLink
                      text="Read more"
                      to={
                        (process.env.BASE === '/' ? '' : process.env.BASE) +
                        item.linkUrl
                      }
                      icon
                      margintop="auto"
                      margintopDesktop="auto"
                    />
                  )}
                </Card>
              )
            })}
        </Grid>

        <CardsWrapperMobile id={'industry-sector'}>
          {cards &&
            cards.map((item, key) => {
              if (key < 4 || showAllCards) {
                return (
                  <Card key={key}>
                    {item.icon && <Icon src={item.icon} alt={item.alt} />}
                    {item.title && <h6>{item.title}</h6>}
                    {item.body && <p>{item.body}</p>}
                    {item.linkUrl && (
                      <InlineLink
                        text="Read more"
                        to={
                          (process.env.BASE === '/' ? '' : process.env.BASE) +
                          item.linkUrl
                        }
                        icon
                        margintop="auto"
                        margintopDesktop="auto"
                      />
                    )}
                  </Card>
                )
              }
            })}
          <BtnContainer>
            <Button onClick={loadMore}>
              {!showAllCards ? 'Show all industry sectors' : 'Show less'}
            </Button>
          </BtnContainer>
        </CardsWrapperMobile>
      </MainGrid>
    </Container>
  )
}

export default IndustryGrid

const Container = styled.section`
  padding-bottom: 50px;

  @media (min-width: 1024px) {
    padding-bottom: 80px;
  }
  & h4 {
    font-weight: bold;
    margin-bottom: 20px;
    @media (min-width: 768px) {
      margin-bottom: 35px;
    }
  }
`

const Grid = styled.div`
  display: none;
  @media (min-width: 768px) {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 20px;
    grid-row-gap: 20px;
  }

  @media (min-width: 1024px) {
    grid-template-columns: repeat(4, 1fr);
  }
`

const Card = styled.div`
  padding: 25px 28px 38px;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.05);
  border: 1px solid #eeeeee;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  @media (min-width: 1024px) {
    padding: 45px 28px 63px;
    margin-bottom: 0px;
  }
  @media (min-width: 1200px) {
    padding: 45px 35px 63px;
  }
  & h6 {
    font-family: Roboto, sans-serif;
    font-weight: bold;
    @media (min-width: 768px) {
      height: 60px; // to keep the spacing consistent when the heading is not on 2 lines
    }
  }

  & p {
    margin-bottom: 24px;
    flex: 1;
    @media (min-width: 1024px) {
      margin-bottom: 38px;
    }
  }
`

const Icon = styled.img`
  width: 42px;
  height: 42px;
  margin-bottom: 20px;

  @media (min-width: 1024px) {
    margin-bottom: 14px;
  }
`
const CardsWrapperMobile = styled.div`
  display: block;
  margin-top: 25px;
  @media (min-width: 768px) {
    display: none;
  }
`

const BtnContainer = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: center;
`
